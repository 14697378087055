import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import MembersBanner from "../components/MembersBanner";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";

class OurMembers extends Component {
    render() {
        const membersData = this.props.data.allWordpressWpMembers.edges;
        return (
            <Layout headerElement={<MembersBanner />}>
                <SEO title="Our Members" />
                <section>
                    <div className="container">
                        <div className="heading-text heading-section text-center" style={{marginBottom: 50}}>
                            <span className="lead">The group’s members recognize that the International Decade for People of African Descent (2015-2024) provides a unique opportunity to build provincial, national and international momentum for change that will benefit people of African descent in Nova Scotia and beyond. Here are our members:</span>
                        </div>
                        <ul className="grid grid-4-columns">
                            <div className="row">
                                { membersData.map(({ node }: any) => (
                                    <div key={node.acf.member_name} className="col-xl-3 col-lg-3 col-md-6">
                                        <div className="post-item border">
                                            <div className="post-item-wrap">
                                                <div className="post-image">
                                                    <a href={node.acf.website_url}>
                                                        <GatsbyImage resolutions={node.acf.logo.localFile.childImageSharp.resolutions} style={{top: 24, left: -24, position: 'inherit'}} />
                                                    </a>
                                                </div>
                                                <div className="post-item-description text-center" style={{height: 133}}>
                                                    <h2 style={{marginTop: 24}}><a href={node.acf.website_url}>{node.acf.member_name}</a></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </ul>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default OurMembers

export const pageQuery = graphql`
query MembersQuery {
    allWordpressWpMembers {
        edges {
        node {
            acf {
            description
            member_name
            website_url
            logo {
                localFile {
                childImageSharp {
                    resolutions(width: 279, height: 100) {
                    src
                    width
                    height
                    srcSet
                    }
                }
                }
            }
            }
        }
        }
    }
}
`